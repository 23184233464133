<script>
import { Head, Link } from "@inertiajs/inertia-vue3";
import Layout from "@/Pages/Shared/LayoutDemo.vue";
import UserMenu from "@/Pages/Shared/UserMenu.vue";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Video } from "@splidejs/splide-extension-video";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
import { defineComponent, onMounted, reactive, ref } from "vue";
import Loader from "@/Pages/Shared/Loader.vue";
import CategoryBox from "@/Pages/Shared/KiralaMotorCategoryBox.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default defineComponent({
    name: "Kirala Motor",
    components: {
        CategoryBox,
        Loader,
        Link,
        Head,
        Splide,
        SplideSlide,
        SplideTrack,
        Video,
        UserMenu,
        Disclosure,
        DisclosureButton,
        DisclosurePanel

    },
    data() {
        return {
            blocks: [
                {
                    title: "Motosiklet Kiralama İçin Gerekli Belgeler Nelerdir?",
                    content:
                        "                <p class=\" text-sm mt-5 font-santralregular \">Motosiklet kiralama işlemi için:</p>\n" +
                        "                <ul class=\"ml-6 list-outside text-sm mt-5 space-y-4 font-santralregular \">\n" +
                        "                    <li class=\"font-santralregular\"> Sürücü belgeniz,</li>\n" +
                        "                    <li class=\"font-santralregular\">Vergi levhanız,</li>\n" +
                        "                    <li class=\"font-santralregular\">Adli sicili kaydınız,</li>\n" +
                        "                    <li class=\"font-santralregular\">Ödeme yapacağınız kredi/banka kartı gereklidir.</li>\n" +
                        "                </ul>" +
                        "                <p class=\" text-sm mt-5 font-santralregular \">Gerekli görülmesi durumunda araç güvenliği ve geçerli mevzuatlar nedeniyle ek bilgi ve belge talebi yapılabilir, bilgi ve belge taleplerini karşılayamayan müşterilerimizin kiralama talepleri iptal edilebilmektedir.</p>\n"
                    ,
                    showBlock: true
                },
                {
                    title: "Kiralama değerlendirme aşamasında hangi ek belgeler istenebilir? İstenilen belgeleri nasıl iletmeyelim? ",
                    content:
                        "                <p class=\" text-sm mt-5 font-santralregular \">İstenen bilgi ve belgeler, e-posta kanalı ile veya panel üzerinden yüklenerek tarafımıza iletilmektedir. Bilgi ve belgeler araç/motor ve diğer ek cihazlarda değişkenlik gösterip kiralama süreniz ve bilgileriniz incelendikten sonra tarafınıza iletilecektir. İkametgâh, sürücü belgesi kopyası, gelir belgesi, ticari firma adına kiralama yapılıyorsa faaliyet belgesi/işyeri kaydı vb. evraklar talep edilebilmektedir.</p>\n",
                    showBlock: false
                },
                { title: "	Kiraladığım aracı nasıl teslim alacağım? 	", content: " <p class=' text-sm mt-5 font-santralregular '>	Kiralama yaptığınız aracı adresinize en yakın yetkili bayiden teslim alabilirsiniz, teslim alacağınız adres ve yetkili servis/bayi tarafınıza bildirilecektir. Aracı teslim aldığınız bayiye veya tarafınıza bildirilecek bayi/depo adresine teslim etmeniz gerekmektedir. 	</p>", showBlock: false }
            ],
            readMoreBlocks: []
        };
    },

    methods: {
        toogleBlock(block) {
            block.showBlock = !block.showBlock;
        },
        string_to_slug(str) {
            str = str.replace(/^\s+|\s+$/g, ""); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to = "aaaaeeeeiiiioooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }

            str = str.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
                .replace(/\s+/g, "-") // collapse whitespace and replace by -
                .replace(/-+/g, "-"); // collapse dashes

            return str;
        }
    },
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    setup() {
        const main = ref("<InstanceType<typeof Splide>>");
        const thumbs = ref("<InstanceType<typeof Splide>>");
        const videos = ["gG5sWlLQyIY", "gRXb2rCKjdk", "4JlAq0RDVRk", "1BzZB-vhCz8", "2O5-0sBhhKw"];

        const mainOptions = {
            type: "loop",
            perPage: 1,
            perMove: 1,
            gap: "1rem",
            pagination: true,
            arrows: false,
            rewind: true,
            autoPlay: true
        };

        const thumbsOptions = {
            type: "slide",
            rewind: true,
            gap: "1rem",
            pagination: false,
            fixedWidth: 110,
            fixedHeight: 70,
            cover: true,
            focus: "center",
            isNavigation: true,
            updateOnMove: true,
            arrows: false
        };

        onMounted(() => {
            const thumbsSplide = thumbs.value?.splide;
            if (thumbsSplide) {
                main.value?.sync(thumbsSplide);
            }
        });

        return {
            main,
            thumbs,
            thumbsOptions,
            mainOptions,
            videos,
            extensions: { Video }
        };
    },
    layout: Layout

});
</script>

<template>

    <Head>
        <title>Motosiklet Kiralama Fiyatları ve Kiralık Motosiklet Modelleri</title>
        <meta name="description" content="Motosiklet kiralama KiralaBunu ile çok kolay! Kiralık motosiklet modellerini keşfetmek ve dilediğiniz motosikleti kiralamak için hemen tıklayın!">
    </Head>

    <div class="">
        <section class=" max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mt-4 mx-auto">
            <div class="mb-6 flex flex-row">
                <div class="w-full lg:pt-2">
                    <div class="sliderclass">
                        <splide :options="{ rewind: true, gap: '1rem', perPage: 1, arrows: false }" class="block mts:hidden ts:hidden mt-4">
                            <splide-slide>
                                <picture>
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.webp" type="image/webp">
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.jpeg" type="image/jpeg">
                                    <img class="w-full" src="../../images/kiralamotor/kiralamotor-banner-1.jpeg" />
                                </picture>
                            </splide-slide>

                        </splide>

                        <splide :options="{ rewind: true, gap: '1rem', perPage: 1, arrows: false }" class="hidden mts:block ts:hidden">
                            <splide-slide>
                                <picture>
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.webp" type="image/webp">
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.jpeg" type="image/jpeg">
                                    <img class="w-full" src="../../images/kiralamotor/kiralamotor-banner-1.jpeg" />
                                </picture>
                            </splide-slide>

                        </splide>

                        <splide :options="{ rewind: true, gap: '1rem', perPage: 1, arrows: false }" class="hidden mts:hidden ts:block">
                            <splide-slide>
                                <picture>
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.webp" type="image/webp">
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.jpeg" type="image/jpeg">
                                    <img class="w-full" src="../../images/kiralamotor/kiralamotor-banner-1.jpeg" />
                                </picture>
                            </splide-slide>
                        </splide>
                    </div>
                    <h2 class="text-2xl md:text-4xl font-santralextrabold text-kbblue text-center my-6">Yola çıkmanın en hızlı ve kolay yolu!</h2>

                </div>
            </div>
        </section>
        <section class=" bg-kb-mid-grey py-10">
            <div class=" max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mx-auto">
                <div class="flex flex-wrap justify-center items-center">
                    <!--                    <div class="w-full lg:w-1/3 my-4 px-2 xl:px-3">-->
                    <!--                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">-->
                    <!--                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">-->
                    <!--                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i1.svg" />-->
                    <!--                            </div>-->
                    <!--                            <div class="w-full md:w-3/4">-->
                    <!--                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Bakım Masrafları Bizden</h2>-->
                    <!--                                <p class="text-sm text-black font-santralregular text-center leading-tight">-->
                    <!--                                    Tüm rutin bakım ve onarımlar bizim-->
                    <!--                                    tarafımızdan düzenli olarak yapılır. Siz-->
                    <!--                                    sadece işinize odaklanın.-->
                    <!--                                </p>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="w-full lg:w-1/2 my-4 px-2 xl:px-3">
                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">
                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">
                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i2.svg" />
                            </div>
                            <div class="w-full md:w-3/4">
                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Sigorta ve MTV Dahil</h2>
                                <p class="text-sm text-black font-santralregular text-center leading-tight">
                                    Zorunlu trafik sigortası ve motorlu taşıtlar vergisi ödemeleri tarafımızdan karşılanır. Ekstra masraf yok!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/2 my-4 px-2 xl:px-3">
                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">
                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">
                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i3.png" />
                            </div>
                            <div class="w-full md:w-3/4">
                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Kira Dönemi Sonunda
                                    Sahip Olma Fırsatı</h2>
                                <p class="text-sm text-black font-santralregular text-center leading-tight">
                                    Kira süresi sonunda avantajlı
                                    fiyatlarla motoru satın alabilirsiniz.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/2 my-4 px-2 xl:px-3">
                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">
                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">
                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i4.svg" />
                            </div>
                            <div class="w-full md:w-3/4">
                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Vergi Avantajları</h2>
                                <p class="text-sm text-black font-santralregular text-center leading-tight">
                                    Kiralama bedelindeki KDV'yi tamamen
                                    düşebilirsiniz. Ayrıca geri kalan tutarları
                                    da gider olarak gelir vergisinden
                                    düşebilirsiniz. KDV ve gelir vergisi
                                    ödemelerinizi minimuma indirin.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--                    <div class="w-full lg:w-1/3 my-4 px-2 xl:px-3">-->
                    <!--                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">-->
                    <!--                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">-->
                    <!--                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i5.svg" />-->
                    <!--                            </div>-->
                    <!--                            <div class="w-full md:w-3/4">-->
                    <!--                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Mevsime Uygun Lastikler</h2>-->
                    <!--                                <p class="text-sm text-black font-santralregular text-center leading-tight">-->
                    <!--                                    Kışlık lastik ihtiyacınız olduğunda,-->
                    <!--                                    talep ettiğiniz an değişim yapılır.-->
                    <!--                                    Güvenliğiniz bizim için önemli.-->
                    <!--                                </p>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="w-full lg:w-1/2 my-4 px-2 xl:px-3">
                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">
                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">
                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i6.svg" />
                            </div>
                            <div class="w-full md:w-3/4">
                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">0 KM Yeni Motorlar</h2>
                                <p class="text-sm text-black font-santralregular text-center leading-tight">
                                    Kullanıma hazır sıfır kilometre araçlar sizi bekliyor.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section class="mt-6 md:mt-4 py-1 md:py-9 ">
            <div class="flex mx-auto flex-col max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl">
                <div class="flex w-full justify-center">
                    <div class="text-2xl md:text-3xl my-2 lg:my-0 mx-0 lg:mx-4 self-center text-center w-full md:w-auto text-kbblue">Motorlarımız</div>
                </div>
                <div class="pl-0 lg:pl-0 lg:p-0 lg:mt-9">
                    <div class="md:w-2/3 mx-auto">
                        <category-box v-for="(product, index) in products" :key="index" :product="product" :new-container-classes="`w-full`" :auth="auth" />
                    </div>
                </div>
            </div>
        </section>

        <section class="mt-12 bg-white py-10 bg-[#f8f8f8]">
            <div class="max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mx-auto">
                <div class="flex flex-wrap justify-center items-center">
                    <div class="w-full lg:w-1/2">
                        <picture>
                            <source srcset="../../images/kiralamotor/kiralamotor-g-1.webp" type="image/webp">
                            <source srcset="../../images/kiralamotor/kiralamotor-g-1.jpg" type="image/jpg">
                            <img class="w-full md:pr-8" src="../../images/kiralamotor/kiralamotor-g-1.jpg" />
                        </picture>
                    </div>
                    <div class="w-full lg:w-1/2 xl:pr-12 pb-6 lg:pb-0 ">
                        <h2 class="text-2xl md:text-3xl font-semibold mb-3 lg:mb-6 text-kbgreen mt-6 md:mt-0">Bizimle iletişime geçin</h2>
                        <div class="flex items-center mb-5">
                            <div class="bg-[#f3fbf6] rounded-full mr-4 lg:mr-6"><a href="tel:+9008502551552" class="flex justify-center items-center w-10 h-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 26.343 25.838">
                                        <path id="phone-handset-solid"
                                            d="M11.175,15.076A14.55,14.55,0,0,0,16.461,18.5l2.693-2.143a.479.479,0,0,1,.543,0l5,3.221a1.429,1.429,0,0,1,.236,2.271L22.59,24.161A2.857,2.857,0,0,1,20,24.926,25.064,25.064,0,0,1,7.747,18.5,24.136,24.136,0,0,1,1.125,6.476,2.7,2.7,0,0,1,1.911,3.94L4.34,1.6a1.429,1.429,0,0,1,2.229.229L9.9,6.761a.45.45,0,0,1,0,.536L7.7,9.933a14.107,14.107,0,0,0,3.471,5.143Z"
                                            transform="translate(-0.049 -0.163)" fill="none" stroke="#231f20" stroke-width="2"></path>
                                    </svg>
                                </a></div>
                            <div><a class="text-lg block font-bold" href="tel:+908502551552">0850 255 1552</a></div>
                        </div>
                        <div class="flex items-center mb-5">
                            <div class="bg-[#f3fbf6] rounded-full mr-4 lg:mr-6"><a href="mailto:destek@kiralabunu.com" class="flex justify-center items-center w-10 h-10">
                                    <svg width="23" height="18" viewBox="0 0 26.325 20.711">
                                        <g id="mail-outline" transform="translate(1 1)">
                                            <path id="Path_2940" data-name="Path 2940" d="M5.714,6.75H25.361A2.339,2.339,0,0,1,27.7,9.089V23.123a2.339,2.339,0,0,1-2.339,2.339H5.714a2.339,2.339,0,0,1-2.339-2.339V9.089A2.339,2.339,0,0,1,5.714,6.75Z"
                                                transform="translate(-3.375 -6.75)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                                            <path id="Path_2941" data-name="Path 2941" d="M7.875,11.25,16.3,17.8l8.42-6.549" transform="translate(-4.133 -7.508)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"></path>
                                        </g>
                                    </svg>
                                </a></div>
                            <div><a class="text-lg block font-bold" href="mailto:destek@kiralabunu.com">destek@kiralabunu.com</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class=" flex justify-center items-center flex-col w-full mb-12 mt-10">
            <div class="w-full max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mx-auto flex flex-col justify-center items-center mb-10">
                <div class="text-3xl md:text-4xl my-2 lg:my-0 mx-0 lg:mx-4 self-center text-center w-full md:w-auto text-kbblue">Bilgilendirme</div>

                <div class="mt-6 flex flex-col justify-center items-center w-full">
                    <div :class="[block.showBlock ? ' py-4 rounded-xl mb-4 w-full px-4' : 'flex justify-between items-center w-full py-4 px-4 rounded-full mb-4']" v-for="(block, index) in blocks" :key="index">
                        <div class="flex justify-between w-full" :class="[!block.showBlock ? '' : '']">
                            <div>
                                <p @click="toogleBlock(block)" class="text-base font-santralextrabold ts:text-xl text-left cursor-pointer">
                                    {{ block.title }}</p>
                            </div>
                            <div class="rounded-full w-8 h-8 bg-white " :class="[block.showBlock ? ' rounded-full ' : ' ']" @click="toogleBlock(block)">
                                <button type="button" class="flex justify-center items-center w-8 h-8" v-if="!block.showBlock">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23.872" height="21.712" viewBox="0 0 23.872 21.712">
                                        <g id="Group_119" data-name="Group 119" transform="translate(0)">
                                            <path id="Path_19" data-name="Path 19" d="M1.6,21.712A1.528,1.528,0,0,1,0,20.261V1.451A1.528,1.528,0,0,1,1.6,0a1.528,1.528,0,0,1,1.6,1.451v18.81A1.528,1.528,0,0,1,1.6,21.712Z"
                                                transform="translate(10.341 0)" fill="#000000" />
                                            <path id="Path_20" data-name="Path 20" d="M1.451,23.872A1.528,1.528,0,0,1,0,22.277V1.6A1.528,1.528,0,0,1,1.451,0,1.528,1.528,0,0,1,2.9,1.6V22.277A1.528,1.528,0,0,1,1.451,23.872Z"
                                                transform="translate(23.872 9.405) rotate(90)" fill="#000000" />
                                        </g>
                                    </svg>
                                </button>
                                <button type="button" class="flex justify-center items-center w-8 h-8" v-if="block.showBlock">
                                    <svg id="Group_119" data-name="Group 119" xmlns="http://www.w3.org/2000/svg" width="22.332" height="2.985" viewBox="0 0 22.332 2.985">
                                        <path id="Path_19" data-name="Path 19" d="M1.492,21.712A1.472,1.472,0,0,1,0,20.261V1.451A1.472,1.472,0,0,1,1.492,0,1.472,1.472,0,0,1,2.985,1.451v18.81A1.472,1.472,0,0,1,1.492,21.712Z"
                                            transform="translate(22.022) rotate(90)" fill="#000" />
                                        <path id="Path_20" data-name="Path 20" d="M1.451,22.332A1.472,1.472,0,0,1,0,20.84V1.492A1.472,1.472,0,0,1,1.451,0,1.472,1.472,0,0,1,2.9,1.492V20.84A1.472,1.472,0,0,1,1.451,22.332Z"
                                            transform="translate(22.332 0.042) rotate(90)" fill="#000" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="mr-5" v-html="block.content" v-if="block.showBlock"></div>
                    </div>
                    <div :class="[block.showBlock ? ' py-4 rounded-xl mb-4 w-full px-4' : 'flex justify-between items-center w-full py-4 px-4 rounded-full mb-4']" v-for="(block, index) in readMoreBlocks" :key="index">
                        <div class="flex justify-between w-full" :class="[!block.showBlock ? '' : '']">
                            <div>
                                <p @click="toogleBlock(block)" class="text-base font-santralextrabold ts:text-xl text-left cursor-pointer">
                                    {{ block.title }}</p>
                            </div>
                            <div class="rounded-full w-8 h-8 bg-white " :class="[block.showBlock ? ' rounded-full ' : ' ']" @click="toogleBlock(block)">
                                <button type="button" class="flex justify-center items-center w-8 h-8" v-if="!block.showBlock">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23.872" height="21.712" viewBox="0 0 23.872 21.712">
                                        <g id="Group_119" data-name="Group 119" transform="translate(0)">
                                            <path id="Path_19" data-name="Path 19" d="M1.6,21.712A1.528,1.528,0,0,1,0,20.261V1.451A1.528,1.528,0,0,1,1.6,0a1.528,1.528,0,0,1,1.6,1.451v18.81A1.528,1.528,0,0,1,1.6,21.712Z"
                                                transform="translate(10.341 0)" fill="#000000" />
                                            <path id="Path_20" data-name="Path 20" d="M1.451,23.872A1.528,1.528,0,0,1,0,22.277V1.6A1.528,1.528,0,0,1,1.451,0,1.528,1.528,0,0,1,2.9,1.6V22.277A1.528,1.528,0,0,1,1.451,23.872Z"
                                                transform="translate(23.872 9.405) rotate(90)" fill="#000000" />
                                        </g>
                                    </svg>
                                </button>
                                <button type="button" class="flex justify-center items-center w-8 h-8" v-if="block.showBlock">
                                    <svg id="Group_119" data-name="Group 119" xmlns="http://www.w3.org/2000/svg" width="22.332" height="2.985" viewBox="0 0 22.332 2.985">
                                        <path id="Path_19" data-name="Path 19" d="M1.492,21.712A1.472,1.472,0,0,1,0,20.261V1.451A1.472,1.472,0,0,1,1.492,0,1.472,1.472,0,0,1,2.985,1.451v18.81A1.472,1.472,0,0,1,1.492,21.712Z"
                                            transform="translate(22.022) rotate(90)" fill="#000" />
                                        <path id="Path_20" data-name="Path 20" d="M1.451,22.332A1.472,1.472,0,0,1,0,20.84V1.492A1.472,1.472,0,0,1,1.451,0,1.472,1.472,0,0,1,2.9,1.492V20.84A1.472,1.472,0,0,1,1.451,22.332Z"
                                            transform="translate(22.332 0.042) rotate(90)" fill="#000" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="mr-5" v-html="block.content" v-if="block.showBlock"></div>
                    </div>

                    <a href="/kiralamotor-bilgilendirme" class="bg-black font-santralextrabold text-base lg:text-lg text-white rounded-full py-1.5 px-4 self-center font-bold hover:bg-kbgreen">
                        Daha Fazla
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>
